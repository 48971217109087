<template>
    <div :id="blok._uid"
        class="w-full space-y-2xl">
        <div :id="elementId"></div>
    </div>
</template>

<script setup lang="ts">
import type { BlokComponent } from '~~/types';

const properties = defineProps<{
    blok: Extract<BlokComponent, { component: 'sb-flockler' }>
}>();

const elementId = computed(() => properties.blok.flockler_id);

/** This elementId is solely for the embed scripts used in head and body content.
 * The elementID is in the format of 'flockler-embed-<id>' and the script expects only the id after the prefix.
 * This computed property is extracting the id from the elementId and assigning it to the useHead script hrefs.
 */
const elementIdForScript = computed(() => elementId.value.replaceAll('flockler-embed-', ''));
// eslint-disable-next-line max-len
const embedUrl = computed(() => `https://plugins.flockler.com/embed/16f7faa9c87082779605b98a3f2fcfd9/${elementIdForScript.value}`);
useHead({
    link: [
        {
            href: 'https://plugins.flockler.com',
            rel: 'preconnect',
        },
        {
            href: 'https://plugins.flockler.com',
            rel: 'dns-preload',
        },
        {
            href: embedUrl.value,
            rel: 'preload',
            as: 'script',
        },
    ],
    script: [
        {
            src: embedUrl.value,
            tagPosition: 'bodyClose',
        },
    ],
});
</script>
